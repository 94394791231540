/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');
@import './scss/material';
@import './scss/utils';
@import './scss/responsive';
@import './scss/variables';
@import './scss/theme';


@font-face {
  font-family: 'PoppinsBold';
  src: url('../src/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

html,body,p,span,h1,h2,h3,h4,h5,h6,div{
  font-family: 'Montserrat'!important;

}
.mat-tab-label-content{
  font-weight: bold;
  color: #000000;
}

.label-marcas {
  font-family: 'Montserrat'!important;
  color: #C2C9CF !important;
  position: absolute;
  top: -30px;
  font-size: 12px;
}
.mat-form-field-wrapper{
  border-radius: 5px;
}
table{
  box-shadow: 4px 1px 24px 6px rgba(17, 14, 14, 0.1), 0 2px 42px 19px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px!important;
  background-color: transparent!important;
}
.mat-standard-chip{
  border-radius: 5px!important;
}

html, body { height: 100%; }
body { 
    margin: 0; 
    //font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

.border-select{
  border-right: 1px solid #aeadad
}

.dashboard-styles {
  .mat-form-field-wrapper{
    border-radius: 5px;
  }
}
.mat-toolbar.mat-primary {
  background: #0F2238 !important;
    color: white;
    height: 70px;
}

.mat-drawer {
  background-color: #8669F9 !important;
    color: white !important;
}

.mat-list-base .mat-list-item {
    color: white !important;
}

.color-sube { color: green; }
.color-baja { color: red; }
.color-igual { color: gray; }

.color-sube > i{
  color: #fff;
  border-radius: 100%;
  background-color:green;

}
.color-baja > i{
  color: #fff;
  border-radius: 100%;
  background-color:red;
}
.color-igual > i{
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  background-color:#FCEE21;
  padding-bottom: 4px;
  width: 20px;
}
.material-icons{
  font-size: 16px!important; /* Ajusta el tamaño a tu preferencia */
  vertical-align: top;
}

// Checkbox
 
input[type="checkbox"] {
    display: none; 
  }

  // Ranking y desempeno

  /* 토글 버튼 내부의 원. 감춘 체크박스와 연동한다. */
  .toggle-button-switch2 {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 70.5px;
    height: 46.5px;
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 100;
    transition: left 0.3s;
  }

  /* 토글 버튼의 바탕 */
  .toggle-button-text2 {
    overflow: hidden;
    background-color: #7C3088;
    border-radius: 25px;
    box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
    transition: background-color 0.3s;
  }

  /* 토글 버튼의 텍스트 */
  .toggle-button-text-on2,
  .toggle-button-text-off2 {
    float: left;
    width: 50%;
    height: 100%;
    line-height: 50px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  /* 체크박스가 체크 상태이면 토글 버튼 내부의 원을 오른쪽으로 52px 이동 */
  input[type="checkbox"]:checked ~ .toggle-button-switch2 {
    left: 77px;
  }
// Porcentaje y N
  .toggle-button-switch {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 34.5px;
    height: 34.5px;
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 100;
    transition: left 0.3s;
  }

  /* 토글 버튼의 바탕 */
  .toggle-button-text {
    overflow: hidden;
    background-color: #7C3088;
    border-radius: 25px;
    box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
    transition: background-color 0.3s;
  }

  /* 토글 버튼의 텍스트 */
  .toggle-button-text-on,
  .toggle-button-text-off {
    float: left;
    width: 50%;
    height: 100%;
    line-height: 37.5px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
  .boton-excel{
    background-color: #15904c!important;
    border-radius: 25px!important;
    color: white !important;
    font-size: 14px!important;
    height: 40px!important;
    margin-top: 0px!important;
    margin-left: 10px!important;
    width: 130px!important;
}
  /* 체크박스가 체크 상태이면 토글 버튼 내부의 원을 오른쪽으로 52px 이동 */
  input[type="checkbox"]:checked ~ .toggle-button-switch2 {
    left: 77px;
  }

  input[type="checkbox"]:checked ~ .toggle-button-switch {
    left: 38px;
  }
  /* 체크박스가 체크 상태이면 토글 버튼의 배경색 변경 */
  input[type="checkbox"]:checked ~ .toggle-button-text  {
    background-color: #448AFF;
  }
  input[type="checkbox"]:checked ~ .toggle-button-text2  {
    background-color: #448AFF;
  }

